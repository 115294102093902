import { z } from 'zod';
import { addYears, isAfter, isBefore, startOfDay } from 'date-fns';
import { validationMessages } from '@/helpers/utils/textHelpers';
import { getDate, resetSecondsAndMilliseconds } from '@/helpers/utils/dateHelpers';

export const getQualificationMinDate = (driverDateOfBirth: Date) =>
  addYears(startOfDay(driverDateOfBirth), 15);

export const getQualificationMaxDate = () => resetSecondsAndMilliseconds(getDate());

export const baseQualificationFormSchema = z.object({
  type: z
    .string()
    .nullable()
    .refine(value => Boolean(value), validationMessages.required),
  date: z
    .date()
    .nullable()
    .refine(value => !value || !isAfter(value, getQualificationMaxDate()), {
      message: 'Max date is today',
    })
    .refine(value => Boolean(value), validationMessages.required),
});

export const qualificationFormSchema = baseQualificationFormSchema
  .merge(
    z.object({
      _dateOfBirth: z.date().nullable(),
    }),
  )
  .refine(
    ({ date, _dateOfBirth }) =>
      !date || !_dateOfBirth || !isBefore(date, getQualificationMinDate(_dateOfBirth)),
    ({ _dateOfBirth }) => ({
      message: _dateOfBirth
        ? validationMessages.minDate(getQualificationMinDate(_dateOfBirth))
        : undefined,
      path: ['date'],
    }),
  );

export type QualificationFormSchema = z.input<typeof qualificationFormSchema>;
