/* eslint-disable max-lines */
import { z } from 'zod';
import { isAfter, isBefore, subYears } from 'date-fns';
import { claimFormSchema } from './newMtaDriversClaim.schema';
import { convictionFormSchema } from './newMtaDriversConviction.schema';
import {
  baseQualificationFormSchema,
  getQualificationMinDate,
} from './newMtaDriversQualificationSchema';
import { validationMessages } from '@/helpers/utils/textHelpers';
import { YesNo } from '@/models/YesNo';
import { getDate } from '@/helpers/utils/dateHelpers';

export const VOLUNTARY_WORKER_STATUS_CODE = 'V';
export const STUDENT_STATUS_CODE = 'F';
export const EMPLOYED_STATUSES_CODES = ['E', 'S'];

export const getMaxResidentDate = () => getDate();
export const getMinResidentDate = () => subYears(getDate(), 100);
export const getMinBirthDate = () => subYears(getDate(), 100);
export const getMaxBirthDate = () => subYears(getDate(), 16);

export const driversSharedFields = z
  .object({
    _isComplete: z.boolean(),
    _driverId: z.string().optional(),
    _isMainDriver: z.boolean(),
    title: z.string().refine(value => Boolean(value), validationMessages.required),
    firstName: z
      .string()
      .min(2, validationMessages.minLength(2))
      .max(20, validationMessages.maxLength(20)),
    surname: z
      .string()
      .min(2, validationMessages.minLength(2))
      .max(20, validationMessages.maxLength(20)),
    dateOfBirth: z
      .date()
      .nullable()
      .refine(value => Boolean(value), validationMessages.required)
      .refine(value => value && value >= getMinBirthDate(), {
        message: validationMessages.minDate(getMinBirthDate()),
      })
      .refine(value => value && value <= getMaxBirthDate(), {
        message: validationMessages.maxDate(getMaxBirthDate()),
      }),
    maritalStatus: z
      .string()
      .refine(value => Boolean(value), validationMessages.required),
    isUkResidentSinceBirth: z
      .nativeEnum(YesNo)
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
    ukResidentSince: z.date().nullable(),
    employmentStatus: z.string().min(1, { message: validationMessages.required }),
    studentType: z.string().nullable(),
    jobTitle: z.object({ description: z.string(), code: z.string() }).nullable(),
    jobIndustry: z.object({ description: z.string(), code: z.string() }).nullable(),
    anyOtherJob: z.nativeEnum(YesNo).nullable(),
    secondaryJobTitle: z.object({ description: z.string(), code: z.string() }).nullable(),
    secondaryJobIndustry: z
      .object({ description: z.string(), code: z.string() })
      .nullable(),
    drivingLicenceProvided: z
      .nativeEnum(YesNo)
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
    drivingLicenceNumber: z.string(),
    drivingLicenceType: z
      .string()
      .refine(value => Boolean(value), validationMessages.required),
    yearsHeldLicence: z
      .number()
      .nullable()
      .refine(value => typeof value === 'number', validationMessages.required),
    monthsHeldLicence: z.string().optional(),
    ownOrDriveAnotherCar: z
      .nativeEnum(YesNo)
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
    otherVehicleUsage: z.string().optional(),
    hasAdvanceDrivingQualifications: z
      .nativeEnum(YesNo)
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
    advanceDrivingQualifications: z.array(baseQualificationFormSchema),
    medicalConditions: z
      .nativeEnum(YesNo)
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
    medicalCondition: z.string().optional(),
    hasClaims: z
      .nativeEnum(YesNo)
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
    claims: z.array(claimFormSchema),
    _isAddingNewClaim: z
      .boolean()
      .optional()
      .refine(
        value => Boolean(!value),
        'You must save the claim before submitting the form',
      ),
    hasConvictions: z
      .nativeEnum(YesNo)
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
    convictions: z.array(convictionFormSchema),
    _isAddingNewConviction: z
      .boolean()
      .optional()
      .refine(
        value => Boolean(!value),
        'You must save the conviction before submitting the form',
      ),
    hasNonMotoringConvictions: z
      .nativeEnum(YesNo)
      .nullable()
      .refine(value => Boolean(value), validationMessages.required),
  })
  .refine(
    ({ anyOtherJob, secondaryJobTitle }) => {
      if (anyOtherJob === YesNo.Yes) {
        return !!secondaryJobTitle;
      }

      return true;
    },
    {
      message: validationMessages.required,
      path: ['secondaryJobTitle'],
    },
  )
  .refine(
    ({ anyOtherJob, secondaryJobIndustry }) => {
      if (anyOtherJob === YesNo.Yes) {
        return !!secondaryJobIndustry;
      }

      return true;
    },
    {
      message: validationMessages.required,
      path: ['secondaryJobIndustry'],
    },
  )
  .refine(
    ({ employmentStatus, studentType }) => {
      if (employmentStatus === STUDENT_STATUS_CODE) {
        return !!studentType;
      }

      return true;
    },
    { message: validationMessages.required, path: ['studentType'] },
  )
  .refine(
    ({ employmentStatus, anyOtherJob }) => {
      if (EMPLOYED_STATUSES_CODES.includes(employmentStatus)) {
        return !!anyOtherJob;
      }

      return true;
    },
    { message: validationMessages.required, path: ['anyOtherJob'] },
  )
  .refine(
    ({ employmentStatus, jobTitle }) => {
      if (EMPLOYED_STATUSES_CODES.includes(employmentStatus)) {
        return !!jobTitle;
      }

      return true;
    },
    { message: validationMessages.required, path: ['jobTitle'] },
  )
  .refine(
    ({ employmentStatus, jobIndustry }) => {
      if (EMPLOYED_STATUSES_CODES.includes(employmentStatus)) {
        return !!jobIndustry;
      }

      return true;
    },
    { message: validationMessages.required, path: ['jobIndustry'] },
  )
  .refine(
    ({ isUkResidentSinceBirth, ukResidentSince }) => {
      if (isUkResidentSinceBirth === YesNo.No) {
        return !!ukResidentSince;
      }

      return true;
    },
    {
      message: validationMessages.required,
      path: ['ukResidentSince'],
    },
  )
  .refine(
    ({ isUkResidentSinceBirth, ukResidentSince, dateOfBirth }) => {
      if (isUkResidentSinceBirth === YesNo.No && ukResidentSince && dateOfBirth) {
        return isAfter(ukResidentSince, dateOfBirth);
      }

      return true;
    },
    {
      message: 'Date must be greater than date of birth',
      path: ['ukResidentSince'],
    },
  )
  .refine(
    ({ isUkResidentSinceBirth, ukResidentSince }) => {
      if (isUkResidentSinceBirth === YesNo.No && ukResidentSince) {
        return ukResidentSince >= getMinResidentDate();
      }

      return true;
    },
    {
      message: validationMessages.minDate(getMinResidentDate()),
      path: ['ukResidentSince'],
    },
  )
  .refine(
    ({ isUkResidentSinceBirth, ukResidentSince }) => {
      if (isUkResidentSinceBirth === YesNo.No && ukResidentSince) {
        return ukResidentSince <= getDate();
      }

      return true;
    },
    {
      message: validationMessages.maxDate(getDate()),
      path: ['ukResidentSince'],
    },
  )
  .refine(
    ({ drivingLicenceProvided, drivingLicenceNumber }) => {
      if (drivingLicenceProvided === YesNo.Yes) {
        return !(drivingLicenceNumber.length < 16);
      }

      return true;
    },
    {
      path: ['drivingLicenceNumber'],
      message: validationMessages.minLength(16),
    },
  )
  .refine(
    ({ drivingLicenceProvided, drivingLicenceNumber }) => {
      if (drivingLicenceProvided === YesNo.Yes) {
        return !(drivingLicenceNumber.length > 16);
      }

      return true;
    },
    {
      path: ['drivingLicenceNumber'],
      message: validationMessages.maxLength(16),
    },
  ) // TODO validate driving licence number
  .refine(
    ({ medicalConditions, medicalCondition }) => {
      if (medicalConditions !== YesNo.Yes) return true;

      return !!medicalCondition?.length;
    },
    { path: ['medicalCondition'], message: validationMessages.required },
  )
  .refine(
    ({ ownOrDriveAnotherCar, otherVehicleUsage }) => {
      if (ownOrDriveAnotherCar !== YesNo.Yes) return true;

      return !!otherVehicleUsage?.length;
    },
    {
      path: ['otherVehicleUsage'],
      message: validationMessages.required,
    },
  )
  .refine(
    ({ yearsHeldLicence, monthsHeldLicence }) => {
      if (yearsHeldLicence === 0) {
        return Number(monthsHeldLicence) >= 1;
      }

      return true;
    },
    {
      path: ['monthsHeldLicence'],
      message: validationMessages.minValue(1),
    },
  )
  .refine(
    ({ yearsHeldLicence, monthsHeldLicence }) => {
      if (yearsHeldLicence === 0) {
        return Number(monthsHeldLicence) <= 11;
      }

      return true;
    },
    {
      path: ['monthsHeldLicence'],
      message: validationMessages.maxValue(11),
    },
  )
  .superRefine(({ dateOfBirth, advanceDrivingQualifications }, ctx) => {
    if (dateOfBirth) {
      advanceDrivingQualifications.forEach((qualification, index) => {
        if (
          qualification.date &&
          isBefore(qualification.date, getQualificationMinDate(dateOfBirth))
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: validationMessages.minDate(getQualificationMinDate(dateOfBirth)),
            path: ['advanceDrivingQualifications', index, 'date'],
          });
        }
      });
    }
  });

export type DriversSharedFieldsSchema = z.input<typeof driversSharedFields>;
