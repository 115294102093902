import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { DriverNestedFieldDisplay } from '../DriverNestedFieldDisplay';
import { Qualification } from './Qualification';
import { QualificationForm } from './QualificationForm';
import { QualificationFormSchema } from '@/features/NewMta/hooks/schemas/newMtaDriversQualificationSchema';
import { NewMtaFormSchema, useNewMtaDriverContext } from '@/features/NewMta/hooks';
import { YesNo } from '@/models/YesNo';
import { FormRadioGroup } from '@/components/ui/forms/FormRadioGroup';
import { FormRadio } from '@/components/ui/forms/FormRadio';
import { commonSx, maxWidthSx } from '@/helpers/utils/formHelpers';

export function DriverQualificationsFields() {
  const { control, setValue, watch, trigger } = useFormContext<NewMtaFormSchema>();
  const { getFieldName } = useNewMtaDriverContext();
  const { fields, remove, append } = useFieldArray({
    control,
    name: getFieldName('advanceDrivingQualifications'),
  });
  const [shouldShowQualificationForm, setShouldShowQualificationForm] = useState(false);
  const shouldShowQualificationsList =
    watch(getFieldName('hasAdvanceDrivingQualifications')) === YesNo.Yes &&
    !shouldShowQualificationForm;
  const dateOfBirth = watch(getFieldName('dateOfBirth'));

  const onAddQualification = (formValues: QualificationFormSchema) => {
    append(formValues);
    setShouldShowQualificationForm(false);
  };

  const handleRemove = (index: number) => {
    if (fields.length === 1) {
      setValue(getFieldName('hasAdvanceDrivingQualifications'), YesNo.No);
    }
    remove(index);
  };

  useEffect(() => {
    trigger(getFieldName('advanceDrivingQualifications'));
  }, [dateOfBirth]);

  return (
    <>
      <FormRadioGroup
        label="Advance driving qualifications"
        name={getFieldName('hasAdvanceDrivingQualifications')}
        sx={{ ...commonSx, ...maxWidthSx }}
      >
        <FormRadio label={YesNo.Yes} value={YesNo.Yes} />
        <FormRadio label={YesNo.No} value={YesNo.No} />
      </FormRadioGroup>
      {shouldShowQualificationsList ? (
        <>
          <div>
            {fields.map(({ id, type, date }, index) => (
              <DriverNestedFieldDisplay
                key={id}
                name={`Qualification #${index + 1}`}
                onRemove={() => handleRemove(index)}
              >
                <Qualification type={type} date={date} index={index} />
              </DriverNestedFieldDisplay>
            ))}
          </div>
          <Button
            sx={{ alignSelf: 'flex-start' }}
            variant="contained"
            size="small"
            onClick={() => setShouldShowQualificationForm(true)}
          >
            Add another qualification
          </Button>
        </>
      ) : null}
      {shouldShowQualificationForm ? (
        <QualificationForm
          onAddQualification={onAddQualification}
          onCancel={() => setShouldShowQualificationForm(false)}
        />
      ) : null}
    </>
  );
}
