import { FormProvider, useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { DriverNestedFormFields } from '../DriverNestedFormFields';
import { useQualificationForm } from './hooks/useQualificationForm';
import {
  getQualificationMaxDate,
  getQualificationMinDate,
  QualificationFormSchema,
} from '@/features/NewMta/hooks/schemas/newMtaDriversQualificationSchema';
import { NewMtaFormSchema, useNewMtaDriverContext } from '@/features/NewMta/hooks';
import { FormDatePicker } from '@/components/ui/forms/FormDatePicker';
import { FormTextField } from '@/components/ui/forms/FormTextField';
import {
  commonSx,
  maxWidthSx,
  renderSelectOptionsByOptionsCodes,
} from '@/helpers/utils/formHelpers';

type QualificationFormProps = {
  onAddQualification: (fieldValues: QualificationFormSchema) => void;
  onCancel: () => void;
};

export function QualificationForm({
  onAddQualification,
  onCancel,
}: QualificationFormProps) {
  const mtaForm = useFormContext<NewMtaFormSchema>();
  const { drivingQualifications, getFieldName } = useNewMtaDriverContext();
  const dateOfBirth = mtaForm.watch(getFieldName('dateOfBirth'));
  const qualificationForm = useQualificationForm();
  const qualificationDate = qualificationForm.watch('date');

  useEffect(() => {
    qualificationForm.setValue('_dateOfBirth', dateOfBirth);
    if (qualificationDate) {
      qualificationForm.trigger('date');
    }
  }, [dateOfBirth]);

  return (
    <FormProvider {...qualificationForm}>
      <DriverNestedFormFields<QualificationFormSchema>
        form={qualificationForm}
        onAdd={onAddQualification}
        onCancel={onCancel}
        buttonLabel="Add this qualification"
      >
        <FormTextField
          select
          label="Qualification"
          name="type"
          sx={{ ...commonSx, ...maxWidthSx }}
        >
          {renderSelectOptionsByOptionsCodes(drivingQualifications ?? [])}
        </FormTextField>
        <FormDatePicker
          label="Date of qualification"
          name="date"
          sx={{ ...commonSx, ...maxWidthSx }}
          minDate={dateOfBirth ? getQualificationMinDate(dateOfBirth) : undefined}
          maxDate={getQualificationMaxDate()}
        />
      </DriverNestedFormFields>
    </FormProvider>
  );
}
