import { useFormContext } from 'react-hook-form';
import { ListRow } from '@/components/ui/lists/ListRow';
import { NewMtaFormSchema, useNewMtaDriverContext } from '@/features/NewMta/hooks';
import { formatDate } from '@/helpers/utils/dateHelpers';
import { FieldError } from '@/components/ui/forms/FormTextField/components/FieldError';

type QualificationProps = {
  type: string | null;
  date: Date | null;
  index: number;
};

function useQualificationTypeDescription(qualificationType: string | null) {
  const { drivingQualifications } = useNewMtaDriverContext();
  return drivingQualifications?.find(({ code }) => code === qualificationType)
    ?.description;
}

function useQualificationDateFieldError(qualificationIndex: number) {
  const {
    formState: { errors },
  } = useFormContext<NewMtaFormSchema>();
  const { nestIndex } = useNewMtaDriverContext();
  return errors.drivers?.[nestIndex]?.advanceDrivingQualifications?.[qualificationIndex]
    ?.date;
}

export function Qualification({
  type,
  date,
  index: qualificationIndex,
}: QualificationProps) {
  const typeDescription = useQualificationTypeDescription(type);
  const dateFieldError = useQualificationDateFieldError(qualificationIndex);

  return (
    <>
      <ListRow label="Qualification">{typeDescription}</ListRow>
      <ListRow label="Date of qualification">{date ? formatDate(date) : null}</ListRow>
      {dateFieldError && <FieldError m={0}>{dateFieldError.message}</FieldError>}
    </>
  );
}
